<template>
  <ul
    class="sidebar-menu"
    data-widget="tree"
    data-auto-collapse-size="768"
    @mouseenter.prevent.stop="onHoverMenu(false)"
    @mouseleave.prevent.stop="onHoverMenu(true)"
  >
    <!-- <li class="header">{{ $t("main_navigation") }}</li> -->
    <li
      v-for="item in options"
      v-bind:key="item.id"
      v-bind:class="{
        disabled: item.disabled,
        hidden: item.hidden,
        divisor: item.target == '-',
        treeview: item.options && item.options.length
      }"
      @mouseenter="onMouseEnter(item.id)"
      @mouseleave="onMouseLeave(item.id)"
    >
      <MenuItemLink
        v-if="item.type == 'link' && item.target != '-'"
        v-bind:option="item"
        v-bind:multilevel="item.options && item.options.length ? true : false"
        @toggleTreeview="toggleTreeview(item.id, $event)"
      ></MenuItemLink>
      <MenuItemPanel
        v-else-if="item.target != '-'"
        v-bind:option="item"
        v-bind:multilevel="item.options && item.options.length ? true : false"
        :equipmentId="equipmentId"
        @toggleTreeview="toggleTreeview(item.id, $event)"
      ></MenuItemPanel>
      <MenuTreeView
        v-if="item.options && item.options.length ? true : false"
        :items="item.options"
        :id="item.id"
        :equipmentId="equipmentId"
      />
    </li>
  </ul>
</template>

<script>
import MenuItemLink from "./menu-item-link";
import MenuItemPanel from "./menu-item-panel";
import MenuTreeView from "./menu-tree-view";
import MixinRuleValidation from "@/project/mixin-rule-validation";

export default {
  name: "Menu",
  mixins: [MixinRuleValidation],
  components: {
    MenuItemLink,
    MenuItemPanel,
    MenuTreeView
  },
  props: {
    equipmentId: {
      type: Number,
      required: true,
      default: 0
    },
    dashboard: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      hidden_in_path: ["/dashboard/profile"],
      openScreen: null
    };
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    items() {
      var self = this;
      var dashboard = self.dashboard;
      var ret =
        (dashboard &&
          "page_side_bar" in dashboard &&
          "options" in dashboard.page_side_bar &&
          dashboard.page_side_bar.options.length &&
          dashboard.page_side_bar.options) ||
        [];
      return ret;
    },
    lastScreenId() {
      return this.$store.getters["dashboard/getRecentScreenId"];
    },
    connectorList() {
      return this.$store.getters["dashboard/connectorList"] || [];
    },
    options() {
      let contract = this.contract;
      if (!contract) return null;
      //contract["allowed_custom_screens"]=false; // for testing only
      //delete contract.allowed_custom_screens;
      /*      
      var o = {
        name: "DashboardEquipmentEdit",
        label: "Lista de conectores",
        icon: "glyphicon glyphicon-th-list",
        target: "/dashboard/edit",
        hidden: false,
        rule: "EquipamentoCadastro",
        options: [
          {
            label: "Criar conector",
            icon: "fa fa-plug",
            target: "/dashboard/edit/connector/0",
            rule: "EquipamentoCadastro"
          },
          {
            label: "Criar dispositivo",
            icon: "portal-icon device[solid]",
            target: "/dashboard/edit/connector/0/device/0",
            rule: "EstacaoCadastro"
          },
          {
            label: "Criar dado",
            icon: "glyphicon glyphicon-stats",
            target: "/dashboard/edit/connector/0/device/0/data/0",
            rule: "DadoCadastro"
          },
          {
            label: "Criar alarme",
            icon: "fa fa-bell",
            target: "/dashboard/edit/connector/0/device/0/data/0/alarm/0",
            rule: "AlarmeCadastro"
          }
        ]
      };
      */
      const isRouted = (path) => {
        let routes = this.$router.getRoutes() || [];
        for (let ix in routes) {
          if (routes[ix].path != "*" && routes[ix].regex.test(path))
            return true;
        }
        return false;
      };

      const _parseOption = (option) => {
        if (option.target && /^\$\{.*\}/.test(option.target)) {
          option.href = this.$utils.eval(option.target, this.$root.config);
        }
        if (!option.href) {
          option.requireEquipment =
            (option.target || "").indexOf("equipment_id") > 0;
          option.id = "option-" + this.$utils.uuid();
          option.label = this.$t(option.label);
          if (option.target == "/dashboard/screen/edit/:last") {
            option.href = option.target.replace(
              /:?last/,
              this.lastScreenId || "new"
            );
          } else {
            option.href = option.requireEquipment
              ? option.target.replace(/\:?equipment_id/, this.equipmentId)
              : option.target || ".";
          }
        }
        option.type =
          option.href.indexOf("/dashboard") === 0 || isRouted(option.target)
            ? "panel"
            : "link";
        if (option?.options?.length) {
          if (
            option.name == "DashboardEquipmentEdit" &&
            !this.connectorList.length
          ) {
            option.options = option.options.filter(({target}) =>
              target.match(/(\/device|\/data|\/alarm)/) ? false : true
            );
          }
          option.options.forEach((subItem) => {
            _parseOption(subItem);
          });
        }
      };
      var items = JSON.parse(JSON.stringify(this.items));
      return items
        .map((item) => {
          _parseOption(item);
          return item;
        })
        .filter((i) => {
          if (i.rule) {
            // please keep it in different lines for easy logging
            if (this.hidden_in_path.indexOf(this.$route.path) >= 0) {
              return false;
            }
            if (!this.hasPermission(i.rule)) return false;
          }
          if (i.requireEquipment && this.equipmentId == 0) {
            return false;
          }
          if (i.dependsOn) {
            let panel = this.openScreen?.panels?.find?.(
              (p) => p.name == i.dependsOn
            );
            if (panel?.view_permission?.length) {
              return panel.view_permission.some((id) =>
                this.$store.getters["user/hasUserAccessTo"](id)
              );
            }
          }
          return true;
        });
    },
    user() {
      return this.$store.getters["user/loggedUser"];
    },
    hoverMenu() {
      return this.user?.user_profile?.portal_data?.menuBehavior == "hover";
    }
  },
  methods: {
    onHoverMenu(collapse_menu) {
      if (!this.hoverMenu) return;
      let body = document.getElementsByTagName("body")[0];
      if (
        !body.classList.contains("sidebar-collapse") &&
        !body.classList.contains("sidebar-hover")
      ) {
        return;
      }

      if (collapse_menu && body.classList.contains("sidebar-hover")) {
        body.classList.add("sidebar-collapse");
        body.classList.remove("sidebar-hover");
      } else {
        body.classList.remove("sidebar-collapse");
        body.classList.add("sidebar-hover");

        // Delay para evitar sobreposição de texto
        document
          .querySelectorAll("#app > div > aside > ul > li > a > span")
          .forEach((el) => (el.style.display = "none"));
        setTimeout(() => {
          document
            .querySelectorAll("#app > div > aside > ul > li > a > span")
            .forEach((el) => (el.style.display = "initial"));
        }, 100);
      }
    },
    onMouseEnter(id) {
      if (!this.hoverMenu) return;
      this.toggleTreeview(id, "down");
    },
    onMouseLeave(id) {
      if (!this.hoverMenu) return;
      this.toggleTreeview(id, "up");
    },
    toggleTreeview(id, slide_mode) {
      if (slide_mode == "down") {
        $(`#${id}`)
          .stop(true, false)
          .slideDown(500);
      } else if (slide_mode == "up") {
        $(`#${id}`)
          .stop(true, false)
          .slideUp(500);
      } else {
        $(`#${id}`).slideToggle(500);
      }
    },
    updateOpenScreen() {
      this.openScreen = this.$store.getters["dashboard/template"](
        this.$store.getters["dashboard/dashboardScreenId"]
      );
    },
    normalizeLabel(label) {
      return this.$utils.removeDiacritics(
        label
          .split(" ")
          .pop()
          .toLowerCase()
      );
    }
  },
  mounted() {
    this.$root.$on("dashboardReady", this.updateOpenScreen);
  }
};
</script>

<style scoped>
ul.sidebar-menu > li.divisor {
  border-top: 1px solid lightgray;
}
ul.sidebar-menu > li.disabled {
  cursor: not-allowed;
}
ul.sidebar-menu > li.disabled > a {
  pointer-events: none;
}
</style>
