<template>
  <a
    href="javascript:void(0)"
    @click="onClick"
    :class="{'has-portal-icon': isPortalIcon}"
    @mouseenter.stop.prevent="onMouseEnter"
    @mouseleave.stop.prevent="onMouseLeave"
  >
    <Icon :name="option.icon" />
    <span>{{ option.label }}</span>
    <span
      class="pull-right-container"
      :class="{rotated: showOptions}"
      v-if="multilevel && (hoverMenu || menuExpanded || subtree)"
      @click.prevent.stop="toggleOptions"
    >
      <i class="fa fa-angle-right pull-right"></i>
    </span>
  </a>
</template>

<script>
import Icon from "@/components/icons/icon";
const isMenuExpanded = () =>
  document.body.classList.contains("sidebar-open") ||
  !document.body.classList.contains("sidebar-collapse");
export default {
  name: "MenuItemPanel",
  components: {Icon},
  props: {
    option: {
      type: Object,
      required: true
    },
    multilevel: {
      // controls if it will handle hide/show of other level
      type: Boolean,
      required: false,
      default: false
    },
    subtree: {
      // controls if it's inside another level to decide if arrow icon is shown
      type: Boolean,
      required: false,
      default: false
    },
    equipmentId: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      menuExpanded: isMenuExpanded(),
      showOptions: false
    };
  },
  computed: {
    href() {
      let search = "/:equipmentId";
      if (this.option.href.endsWith(search) && this.equipmentId != 0)
        return this.option.href.replace(search, "/" + this.equipmentId);
      else return this.option.href.replace(search, "");
    },
    isPortalIcon() {
      return this.option.icon.startsWith("portal-icon");
    },
    user() {
      return this.$store.getters["user/loggedUser"];
    },
    hoverMenu() {
      return this.user?.user_profile?.portal_data?.menuBehavior == "hover";
    }
  },
  methods: {
    onMouseEnter() {
      if (!this.hoverMenu) return;
      this.$emit("mouseenter");
      this.showOptions = true;
    },
    onMouseLeave() {
      if (!this.hoverMenu) return;
      this.$emit("mouseleave");
      this.showOptions = false;
    },
    toggleOptions() {
      this.showOptions = !this.showOptions;
      this.$emit("toggleTreeview");
    },
    onClick(e) {
      if (this.$route.path == this.href) return;
      if (document.body.classList.contains("sidebar-open")) {
        document.body.classList.remove("sidebar-open");
        const $el = document.body.querySelector(".content-wrapper");
        if ($el) {
          $el.style.opacity = 0.8;
          $el.style.cursor = "wait";
        }
        setTimeout(
          () => {
            this.$router.push(this.href);
            if ($el) {
              $el.style.opacity = 1;
              $el.style.cursor = "initial";
            }
          },
          301,
          this
        ); // side bar transition takes 300ms
        this.menuExpanded = isMenuExpanded();
        return;
      }
      this.menuExpanded = isMenuExpanded();
      this.$router.push(this.href);
    }
  },
  mounted() {
    // if (!this.subtree)
    //   $(document)
    //     .on("expanded.pushMenu", () => (this.menuExpanded = true))
    //     .on("collapsed.pushMenu", () => (this.menuExpanded = false));
    if (!this.subtree)
      $(document)
        .on("expanded.pushMenu", () => {
          this.menuExpanded = isMenuExpanded();
        })
        .on("collapsed.pushMenu", () => {
          this.menuExpanded = isMenuExpanded();
        });
    // if (!this.subtree) this.statusUpdate();
  }
};
</script>
<style scoped>
.pull-right-container {
  display: inline-block;
  padding: 0 5px 0 10px;
}
.pull-right-container > i {
  transition: transform 300ms;
  transform-origin: 42% center;
  font-weight: 600;
}

.pull-right-container:hover {
  color: black;
}

.skin-dark .pull-right-container:hover {
  color: var(--skin-dark-white);
}

.pull-right-container.rotated > i {
  transform: rotate(90deg);
}

.has-portal-icon {
  display: inline-flex;
  align-items: center;
  gap: 6px;
}
</style>
