<template>
  <aside
    v-if="widget"
    class="control-sidebar-bg control-sidebar control-sidebar-light"
    v-bind:class="collapsed ? '' : 'control-sidebar-open'"
    ref="controlSidebar"
    :style="style"
    v-closable="{
      handler: 'closeAway',
      allowed: clickAway && clickAway.allowed
    }"
    @mouseenter.stop.prevent="onMouseEnter(true)"
    @mouseleave.stop.prevent="onMouseEnter(false)"
    @mousewheel="onMouseWheel($event)"
  >
    <div class="dragger" @mousedown="handleMousedown($event)" />
    <div style="position: relative" v-if="showToggle">
      <div
        class="side-panel-toggle"
        @click.stop.prevent="collapsed = !collapsed"
      >
        <span>
          <i
            class="fa pull-right fa-angle-right"
            style="transition: transform 0.5s ease-in-out"
            :style="{transform: `rotate(${collapsed ? 180 : 0}deg)`}"
          ></i>
        </span>
      </div>
    </div>
    <slot>
      <component
        :is="widget"
        class="drawer-content"
        ref="drawerContent"
      ></component>
    </slot>
  </aside>
</template>

<script>
import closable from "@/directives/closable";
import {whichTransitionEvent} from "@/utils";

const defs = {
  iniWidth: 320,
  minWidth: 250,
  maxWidth: 600,
  maxTopPadding: {
    largeScreen: 50,
    smallScreen: 50
  }
};

export default {
  name: "ControlSideBar",
  directives: {closable},
  data() {
    return {
      widget: null,
      collapsed: true,
      paddingTop: defs.maxTopPadding.largeScreen,
      showToggle: true,
      clickAway: null,
      resizing: false,
      width: defs.iniWidth
    };
  },
  computed: {
    style() {
      let style = {
        width: `${this.width}px`,
        "padding-top": `${this.paddingTop}px`
      };
      if (this.collapsed) {
        style.right = `-${this.width}px`;
      }
      return style;
    }
  },
  methods: {
    setWidget(
      widget,
      {toggle = false, show, clickAway = false, showToggle = true} = {}
    ) {
      if (this.widget == widget && widget) {
        this.collapsed = toggle
          ? !this.collapsed
          : show != undefined
          ? !show
          : false;
      } else {
        if (widget) {
          if (this.widget) {
            this.$delete(this.widget);
          }
          this.$set(this, "widget", widget);
          this.$store.commit("dashboard/SET_SIDEBAR", {
            name: widget?.name || "unnamed",
            collapsed: this.collapsed
          });

          this.showToggle = showToggle;
          this.clickAway = clickAway;
          setTimeout(() => (this.collapsed = false), 0);
        } else if (widget != this.widget) {
          if (!this.collapsed && this.$refs.controlSidebar) {
            this.$refs.controlSidebar.addEventListener(
              whichTransitionEvent(),
              () => {
                this.$set(this, "widget", widget);
                this.$store.commit("dashboard/SET_SIDEBAR", null);
              }
            );
          } else {
            this.$set(this, "widget", widget);
            this.$store.commit("dashboard/SET_SIDEBAR", null);
          }
          this.clickAway = null;
          this.collapsed = true;
        }
      }
      this.resizing = false;
    },
    updatePosition() {
      let offset =
        defs.maxTopPadding[
          window.innerWidth > 768 ? "largeScreen" : "smallScreen"
        ];
      let paddingTop = offset - document.documentElement.scrollTop;
      this.paddingTop = paddingTop > 0 ? paddingTop : 0;
      this.resizing = false;
    },
    closeAway() {
      if (this.resizing) {
        this.resizing = false;
        return;
      }
      if (this.clickAway) this.collapsed = true;
    },
    handleMousedown(e) {
      this.resizing = true;
    },
    handleMousemove(e) {
      if (!this.resizing) {
        return;
      }
      let offsetRight =
        document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
      if (offsetRight > defs.minWidth && offsetRight < defs.maxWidth) {
        this.width = offsetRight;
      }
    },
    handleMouseup(e) {
      if (this.clickAway) {
        setTimeout(() => {
          this.resizing = false;
        }, 100);
      } else {
        this.resizing = false;
      }
    },
    setCollapse(value) {
      this.collapsed = value;
    },
    onMouseEnter(opt) {
      // console.log(`onMouseEnter ${opt}`);
      if (opt && this.$el && this.$el.focus) {
        this.$el.focus();
        // document.body.style.overflowY = "hidden";
      } else {
        // document.body.style.overflowY = "auto";
        document.body.focus();
      }
    },
    onMouseWheel($e) {
      if (this.$el) {
        let scrollTop = this.$el.scrollTop;
        let delta = $e.deltaY > 133 ? 133 : $e.deltaY < -133 ? -133 : $e.deltaY;
        this.$el.scrollTop = this.$el.scrollTop + 6 * delta;
        if (
          (delta < 0 && scrollTop == 0) ||
          (delta > 0 &&
            this.$el.scrollTop >= 0 &&
            this.$el.scrollTop == scrollTop)
        )
          return;
        $e.preventDefault();
        $e.stopPropagation();
      }
    }
  },
  watch: {
    collapsed(n) {
      let sidebar = this.$store.getters["dashboard/sidebar"] || null;
      if (sidebar) {
        sidebar.collapsed = n;
        this.$store.commit("dashboard/SET_SIDEBAR", sidebar);
      }
    }
  },
  created() {
    document.addEventListener("scroll", this.updatePosition);
    window.addEventListener("resize", this.updatePosition);
    this.$root.$on("controlSidebar:setContent", this.setWidget.bind(this));
    this.$root.$on("controlSidebar:collapse", this.setCollapse);
  },
  beforeDestroy() {
    document.removeEventListener("mousemove", this.handleMousemove);
    document.removeEventListener("mouseup", this.handleMouseup);
    document.removeEventListener("scroll", this.updatePosition);
    window.removeEventListener("resize", this.updatePosition);
    this.$root.$off("controlSidebar:collapse", this.setCollapse);
    this.$root.$off("controlSidebar:setContent", this.setWidget.bind(this));
  },
  mounted() {
    this.updatePosition();
    document.addEventListener("mousemove", (e) => this.handleMousemove(e));
    document.addEventListener("mouseup", (e) => this.handleMouseup(e));
  }
};
</script>

<style>
.control-sidebar {
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
  scroll-behavior: smooth;
}

.control-sidebar > * > .tab-content {
  padding-inline: 15px;
  background: white;
  padding-bottom: 10px;
}

.skin-dark .control-sidebar > * > .tab-content {
  background: var(--skin-dark-dark);
}

.side-panel-toggle {
  display: flex;
  position: fixed;
  top: 142px;
  min-width: 30px;
  height: 38px;
  padding-right: 5px;
  border-top-left-radius: 19px;
  border-bottom-left-radius: 19px;
  white-space: nowrap;
  overflow: hidden;
  box-shadow: inset -6px 0 8px -10px rgba(0, 0, 0, 0.95);
  z-index: 1;
  transform: translateX(-100%);
  cursor: pointer;
  max-width: 40px;
  background-color: whitesmoke;
  transition: top 0.3s;
  box-shadow: 0px 1px 2px #bdbcbc;
}

.skin-dark .side-panel-toggle {
  color: var(--skin-dark-dark);
  background-color: var(--skin-dark-light);
  box-shadow: 0px 1px 2px var(--skin-dark-allblack);
}

.skin-dark .side-panel-toggle:hover {
  background: #fff;
}

@media (min-width: 768px) {
  .side-panel-toggle {
    top: 102px;
  }
}

.side-panel-toggle > span {
  font-size: 18pt;
  padding: 7px 8px;
}

.dragger {
  position: absolute;
  width: 3px;
  cursor: ew-resize;
  padding: 0;
  border: 0;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  background-color: #f4f7f9;
}

.skin-dark .dragger {
  background-color: var(--skin-dark-medium);
}

.control-sidebar::-webkit-scrollbar {
  width: 0; /* width of the entire scrollbar  */
  height: 0;
}

.drawer-content {
  /* overflow-x: hidden;
  overflow-y: auto; */
}
.drawer-content::-webkit-scrollbar {
  /* width: 0; width of the entire scrollbar */
  /* height: 0; */
}
</style>
